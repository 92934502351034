import React, { useState } from 'react'

import { useUserContext } from '../../context/user/UserProvider'
import { useSiteContext } from '../../context/site/SiteProvider'
import { Breadcrumb } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { isEmbedded, isHideNavPath } from '../../helpers'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { StaffAccountBreadcrumb } from '../pages/settings/staff-account'
import MobileHeaderMenu from './Header/MobileHeaderMenu'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { CustomAssortmentBreadcrumb } from '../pages/products/custom-assortment'
import { ProductsDealBreadcrumb } from '../pages/products/products-deal'
import { ProductBreadcrumb } from '../pages/products/products-editor/tabs/pro-line-tab'
import { ProductsDealsBreadcrumb } from '../pages/products/products-deals'
import { ProductsPricingBreadcrumb } from '../pages/products/products-pricing/products-pricing-tab'
import { ExportInvoicesBreadcrumb } from '../pages/accounting/invoice/export'
import { Link } from 'react-router-dom'
import { PermissionBreadcrumb } from '../pages/iam/permission'
import { RoleBreadcrumb } from '../pages/iam/role'
import { UserRolesBreadcrumb } from '../pages/iam/user-roles'
import { UserProfileBreadcrumb } from '../pages/iam/user-profile'
import { ManufacturerDetailBreadcrumb } from '../pages/pricing/price-manufacturers/detail'
import { BasketOfGoodsDetailBreadcrumb } from '../pages/pricing/summary/basket-of-goods/detail'
import { PhysicalInventoryLogsBreadcrumb } from '../pages/inventory/wms-inventory-taker-logs'
import { PhysicalInventoryBreadcrumb } from '../pages/inventory/wms-inventory-taker'

interface IBreadcrumbs {}

const Breadcrumbs: React.FC = () => {
  const { isDarkMode } = useUserContext()
  const { backPath, userShowCrumbs } = useSiteContext()
  const location = useLocation()
  const navigate = useNavigate()

  const routes = [
    {
      path: '/settings/staff-accounts/:staff_id',
      breadcrumb: StaffAccountBreadcrumb,
    },
    {
      path: '/products/custom-assortment/:case_id/:version',
      breadcrumb: CustomAssortmentBreadcrumb,
    },
    {
      path: '/accounting/products/deals',
      breadcrumb: ProductsDealsBreadcrumb,
    },
    {
      path: '/accounting/product-pricing-projection-versions/:version_id/basket/:basket_of_goods_id',
      breadcrumb: BasketOfGoodsDetailBreadcrumb,
    },
    {
      path: '/accounting/products/deal/:deal_id',
      breadcrumb: ProductsDealBreadcrumb,
    },
    {
      path: 'accounting/product-pricing-projection-versions/:version_id/manufacturers/:price_manufacturers_id',
      breadcrumb: ManufacturerDetailBreadcrumb,
    },
    {
      path: '/accounting/products-pricing/:products_id',
      breadcrumb: ProductsPricingBreadcrumb,
    },
    {
      path: '/products/:products_id',
      breadcrumb: ProductBreadcrumb,
    },
    {
      path: 'accounting/invoice/export',
      breadcrumb: ExportInvoicesBreadcrumb,
    },
    {
      path: '/iam/permissions/:id',
      breadcrumb: PermissionBreadcrumb,
    },
    {
      path: '/iam/roles/:id',
      breadcrumb: RoleBreadcrumb,
    },
    {
      path: '/iam/users/:staff_id',
      breadcrumb: UserRolesBreadcrumb,
    },
    {
      path: '/iam/user-profiles/:id',
      breadcrumb: UserProfileBreadcrumb,
    },
    {
      path: '/products-pro-line/:products_id',
      breadcrumb: ProductBreadcrumb,
    },
    {
      path: '/wms/inventory-taker/:physical_inventory_id',
      breadcrumb: PhysicalInventoryLogsBreadcrumb,
    },
    {
      path: '/wms/inventory-taker',
      breadcrumb: PhysicalInventoryBreadcrumb,
    },
  ]

  const excludePaths = ['/settings', '/accounting', '/wms']
  const breadcrumbs = useBreadcrumbs(routes, { excludePaths: excludePaths })

  const handleBack = () => {
    navigate(backPath)
  }

  if (isHideNavPath(location.pathname) || !userShowCrumbs) {
    return <></>
  }

  const showBackArrow = location.pathname !== '/' && !isEmbedded()

  return (
    <>
      <Breadcrumb
        className={`p-3 text-capitalize rw-breadcrumb fs-4 ${
          isDarkMode
            ? 'rw-breadcrumb-dm bg-dm-body-bg'
            : 'rw-breadcrumb-lm bg-white'
        }`}
      >
        <MobileHeaderMenu />
        {showBackArrow && (
          <span onClick={handleBack} className="cursor-pointer">
            <FaLongArrowAltLeft className="fs-1 my-auto me-2" />
          </span>
        )}

        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          const active = index === breadcrumbs.length - 1
          return isEmbedded() && !active ? (
            <></>
          ) : (
            <Breadcrumb.Item
              active={active}
              key={match.pathname}
              className="text-decoration-none"
              linkAs={Link}
              linkProps={{ to: match.pathname }}
            >
              {isEmbedded() ? breadcrumb : <span>{breadcrumb}</span>}
            </Breadcrumb.Item>
          )
        })}
      </Breadcrumb>
    </>
  )
}

export default Breadcrumbs
