import React from 'react'
import { useUserContext } from '../../../context/user/UserProvider'
import RwInputGroup from '../RwInputGroup'

export interface ISearchInput {
  className?: string
  handleChange: (searchText: string) => void
  placeholder?: string
  minLength?: number
  width?: string
  [x: string]: any
}

const RwNav: React.FC<ISearchInput> = ({
  handleChange,
  className = '',
  placeholder = 'Search',
  minLength = 3,
  width = '300px',
  ...rest
}) => {
  const { isDarkMode } = useUserContext()

  return (
    <RwInputGroup
      placeholder={placeholder}
      className={`${className} p-3`}
      onChange={(e: any) => {
        if (e.target.value.length >= minLength || e.target.value.length === 0) {
          handleChange(e.target.value)
        }
      }}
      style={{ width: width }}
      {...rest}
    />

    //   <FormControl
    //     type="text"
    //     placeholder={placeholder}
    //     className={`${className} p-3`}
    //     onChange={(e) => {
    //       if (
    //         e.target.value.length >= minLength ||
    //         e.target.value.length === 0
    //       ) {
    //         handleChange(e.target.value)
    //       }
    //     }}
    //     style={{ width: width }}
    //     {...rest}
    //   />
  )
}

export default RwNav
