import React, {
  JSXElementConstructor,
  MouseEventHandler,
  ReactElement,
} from 'react'
import { ISortColumn } from '.'

export interface IRwTableHead {
  children:
    | Array<ReactElement<any, string | JSXElementConstructor<any>>>
    | ReactElement<any, string | JSXElementConstructor<any>>
  className?: string
  sortable?: boolean
  handleSortClick?: (sortProp: string) => {}
  sort?: ISortColumn
  [x: string]: any
}

const RwTableHead: React.FC<IRwTableHead> = ({
  children,
  className = '',
  sortable = true,
  handleSortClick,
  sort,
  ...rest
}) => {
  return (
    <thead className={`${className}`} {...rest}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          handleSortClick: handleSortClick,
          sort: sort,
          sortable: sortable,
        })
      })}
    </thead>
  )
}

export default RwTableHead
