import React from 'react'

export interface IRwTableHead {
  className?: string
  [x: string]: any
}

const RwTableHead: React.FC<IRwTableHead> = ({
  children,
  className = '',
  ...rest
}) => {
  return (
    <thead className={`${className}`} {...rest}>
      {children}
    </thead>
  )
}

export default RwTableHead
