import { createContext, useReducer, useContext, useEffect } from 'react'
import {
  GET_MAIN_NAV_MENU_ITEMS,
  GET_TOP_NAV_MENU_ITEMS,
  GET_STORE_SETTINGS,
  GET_APP_INFO,
  SET_APP_VERSION_CURRENT,
  GET_APP_SETTINGS,
  TOGGLE_MENU_ITEM_OPEN,
  SET_MENU_ITEM_SELECTED,
  SET_SHOW_TOP_NAV,
  SET_SHOW_SIDE_NAV,
  SET_USER_SHOW_TOP_NAV,
  SET_USER_SHOW_SIDE_NAV,
  SET_BACK_PATH,
  SET_USER_SHOW_CRUMBS,
  GET_AUDIT_LIST,
  GET_WAREHOUSE_PRINTERS,
} from './constants'
import siteReducer from './SiteReducer'
import { ISiteState, IExportToCsvData, IWarehousePrintJob } from './types'
import { PUBLIC_API_SITE_URL, API_SITE_URL, API_AUDIT_URL } from '../apiconfig'
import { IContextProviderProps } from '../../types/interfaces'
import {
  initiallySelectedStoreSettings,
  initiallySelectedAppSettings,
} from '../../constants/constants'
import { projectId } from '../../constants/db'
import { PROJECT_ID } from '../../helpers'
import { api } from '../../helpers/Api'
import toast from '../../components/molecules/RwToast'

export const initialState: ISiteState = {
  mainNavMenuItems: null,
  topNavMenuItems: null,
  storeSettings: null,
  appInfo: null,
  appVersionCurrent: true,
  appSettings: null,
  showTopNav: true,
  showSideNav: true,
  userShowTopNav: true,
  userShowSideNav: true,
  userShowCrumbs: true,
  backPath: '/',
  auditList: [],
  warehousePrinters: [],
  getMainNavMenuItems: () => {},
  getTopNavMenuItems: () => {},
  toggleMenuItemOpen: () => {},
  setMenuItemSelected: () => {},
  clearMenuItemSelected: () => {},
  getStoreSettings: () => {},
  getAppInfo: () => {},
  setAppVersionCurrentStatus: () => {},
  getAppSettings: () => {},
  setShowTopNav: () => {},
  setShowSideNav: () => {},
  setShowNavs: () => {},
  setUserShowTopNav: () => {},
  setUserShowSideNav: () => {},
  setUserShowCrumbs: () => {},
  setBackPath: () => {},
  exportToCsv: () => {},
  getAuditList: () => {},
  clearAuditList: () => {},
  getWarehousePrinters: () => {},
  cleanupWarehousePrinters: () => {},
  printToWarehousePrinter: () => {},
}

export const SiteContext = createContext(initialState)

export const useSiteContext = () => useContext(SiteContext)

export const SiteProvider = (props: IContextProviderProps): JSX.Element => {
  const { children } = props

  const [state, dispatch] = useReducer(siteReducer, initialState)

  const getMainNavMenuItems = async () => {
    const response = await api.get(
      `${API_SITE_URL}get-menu-items/spark_main_nav`,
    )

    dispatch({
      type: GET_MAIN_NAV_MENU_ITEMS,
      payload: response?.data,
    })
  }

  const getTopNavMenuItems = async () => {
    const response = await api.get(
      `${API_SITE_URL}get-menu-items/spark_top_nav`,
    )

    dispatch({
      type: GET_TOP_NAV_MENU_ITEMS,
      payload: response?.data,
    })
  }

  const toggleMenuItemOpen = async (id: string) => {
    dispatch({
      type: TOGGLE_MENU_ITEM_OPEN,
      payload: id,
    })
  }

  const setMenuItemSelected = async (id: string) => {
    dispatch({
      type: SET_MENU_ITEM_SELECTED,
      payload: id,
    })
  }

  const clearMenuItemSelected = async () => {
    dispatch({
      type: SET_MENU_ITEM_SELECTED,
      payload: null,
    })
  }

  const getStoreSettings = async (storeSettings: Array<string>) => {
    const response = await api.post(
      `${API_SITE_URL}get-store-settings`,
      storeSettings,
    )

    dispatch({
      type: GET_STORE_SETTINGS,
      payload: response?.data,
    })
  }

  const getAppSettings = async (appSettings: Array<string>) => {
    const response = await api.post(
      `${API_SITE_URL}get-app-settings`,
      appSettings,
    )

    dispatch({
      type: GET_APP_SETTINGS,
      payload: response?.data,
    })
  }

  const getAppInfo = async (projectId: number) => {
    const response = await api.post(`${PUBLIC_API_SITE_URL}get-app-info`, {
      project_id: projectId,
    })

    const data = response.data[0]
    const storedAppVersion = localStorage.getItem('version')
    if (storedAppVersion === null) {
      localStorage.setItem('version', data.app_version.toString())
    } else {
      if (storedAppVersion !== data.app_version) {
        setAppVersionCurrentStatus(false) // triggers the modal
      }
    }

    if (storedAppVersion !== data.app_version) {
      dispatch({
        type: GET_APP_INFO,
        payload: data,
      })
    }
  }

  const setAppVersionCurrentStatus = async (
    appVersionCurrentStatus: boolean,
  ) => {
    if (appVersionCurrentStatus) {
      // will set to "true" if user clicks "ok" on modal,
      // and remove the version from localStorage
      localStorage.removeItem('version')
    }
    dispatch({
      type: SET_APP_VERSION_CURRENT,
      payload: appVersionCurrentStatus,
    })
  }

  const setShowTopNav = (showTopNav: boolean) => {
    dispatch({
      type: SET_SHOW_TOP_NAV,
      payload: showTopNav,
    })
  }

  const setShowSideNav = (showSideNav: boolean) => {
    dispatch({
      type: SET_SHOW_SIDE_NAV,
      payload: showSideNav,
    })
  }

  const setShowNavs = (showNavs: boolean) => {
    dispatch({
      type: SET_SHOW_TOP_NAV,
      payload: showNavs,
    })
    dispatch({
      type: SET_SHOW_SIDE_NAV,
      payload: showNavs,
    })
  }

  const setUserShowTopNav = (userShowTopNav: boolean) => {
    dispatch({
      type: SET_USER_SHOW_TOP_NAV,
      payload: userShowTopNav,
    })
  }

  const setUserShowSideNav = (userShowSideNav: boolean) => {
    dispatch({
      type: SET_USER_SHOW_SIDE_NAV,
      payload: userShowSideNav,
    })
  }

  const setBackPath = (backPath: string) => {
    dispatch({
      type: SET_BACK_PATH,
      payload: backPath,
    })
  }

  const setUserShowCrumbs = (userShowCrumbs: boolean) => {
    dispatch({
      type: SET_USER_SHOW_CRUMBS,
      payload: userShowCrumbs,
    })
  }

  const exportToCsv = async (data: IExportToCsvData, filename: string) => {
    const response = await api.post(`${API_SITE_URL}export-to-csv`, data)

    const url = window.URL.createObjectURL(new Blob([response.data.original]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }

  const getAuditList = async (table: string, key_value: string) => {
    const response = await api.get(
      `${API_AUDIT_URL}get-audit-list/${table}/${key_value}`,
    )

    dispatch({
      type: GET_AUDIT_LIST,
      payload: response.data,
    })
  }

  const clearAuditList = async () => {
    dispatch({
      type: GET_AUDIT_LIST,
      payload: [],
    })
  }

  const getWarehousePrinters = async () => {
    const response = await api.get(`${API_SITE_URL}get-warehouse-printers`)

    dispatch({
      type: GET_WAREHOUSE_PRINTERS,
      payload: response.data,
    })
  }

  const cleanupWarehousePrinters = async () => {
    dispatch({
      type: GET_WAREHOUSE_PRINTERS,
      payload: [],
    })
  }

  const printToWarehousePrinter = async (printData: IWarehousePrintJob) => {
    const response = await api.post(`${API_SITE_URL}warehouse-print`, printData)

    if (response.data.success) {
      toast.success(response.data.message)
    } else {
      toast.error(response.data.message)
    }
  }

  useEffect(() => {
    getStoreSettings(initiallySelectedStoreSettings)
    getAppSettings(initiallySelectedAppSettings)

    const intervalId = setInterval(() => {
      getAppInfo(projectId)
    }, 5 * 60 * 1000) // 5 minutes

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const value = {
    ...state,
    getMainNavMenuItems,
    getTopNavMenuItems,
    toggleMenuItemOpen,
    setMenuItemSelected,
    clearMenuItemSelected,
    getStoreSettings,
    getAppInfo,
    setAppVersionCurrentStatus,
    getAppSettings,
    setShowTopNav,
    setShowSideNav,
    setShowNavs,
    setUserShowTopNav,
    setUserShowSideNav,
    setUserShowCrumbs,
    setBackPath,
    exportToCsv,
    getAuditList,
    clearAuditList,
    getWarehousePrinters,
    cleanupWarehousePrinters,
    printToWarehousePrinter,
  }

  return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>
}
