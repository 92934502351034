import React, { useState } from 'react'
import ProductsPricing from './products-pricing-tab'
import { Tab, Tabs } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import NextTab from './next-tab'

const ProductsPricingTabs: React.FC = () => {
  const navigate = useNavigate()
  const { tab } = useParams()
  const { products_id } = useParams()
  const [activeTab, setActiveTab] = useState(tab || 'products-pricing')

  const handleClick = (eventKey: string | null) => {
    if (!eventKey) return
    navigate(`/accounting/products-pricing/${products_id}/${eventKey}`)
    setActiveTab(eventKey)
  }

  return (
    <Tabs activeKey={activeTab} onSelect={handleClick}>
      <Tab eventKey="pricing" title="Base Price & Cost">
        {activeTab === 'pricing' && <ProductsPricing />}
      </Tab>
      <Tab eventKey="test" title="Not Available" disabled>
        <NextTab />
      </Tab>
    </Tabs>
  )
}

export default ProductsPricingTabs
