import React, { JSXElementConstructor, ReactElement } from 'react'
import { Table } from 'react-bootstrap'
import { useUserContext } from '../../../context/user/UserProvider'

interface IRwTable {
  children:
    | Array<ReactElement<any, string | JSXElementConstructor<any>>>
    | ReactElement<any, string | JSXElementConstructor<any>>
  className?: string
  data?: Array<any>
  handleChangeData?: any
  handleChangeSort?: any
  sort?: ISortColumn
  [x: string]: any
}
export interface ISortColumn {
  name: string
  dir: 'asc' | 'desc'
}

const RwTable: React.FC<IRwTable> = ({
  children,
  className = '',
  data = null,
  handleChangeData = null,
  handleChangeSort = null,
  sort = null,
  ...rest
}) => {
  const { isDarkMode } = useUserContext()

  const handleSortClick = (sortProp: string) => {
    toggleSort(sortProp)
  }

  const toggleSort = async (name: string) => {
    const sortVal: ISortColumn =
      !sort || sort.name !== name
        ? { name: name, dir: 'asc' }
        : { name: name, dir: sort.dir === 'asc' ? 'desc' : 'asc' }

    data?.sort((a, b) => {
      let aVal = a[sortVal?.name as any]
      aVal = aVal ? aVal.toString().toLowerCase() : ''

      let bVal = b[sortVal?.name as any]
      bVal = bVal ? bVal.toString().toLowerCase() : ''

      return (
        (aVal > bVal ? 1 : aVal < bVal ? -1 : 0) *
        (sortVal?.dir === 'asc' ? 1 : -1)
      )
    })

    handleChangeSort && handleChangeSort(sortVal)
    handleChangeData && handleChangeData(data)
  }

  return (
    <>
      <Table
        className={`${isDarkMode ? 'rw-table-dm' : 'rw-table'} ${className}`}
        {...rest}
      >
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            handleSortClick: handleSortClick,
            sort: sort,
            sortable: !!data,
          })
        })}
      </Table>
    </>
  )
}

export default RwTable
