import './App.scss'
import './scss/custom.scss'
import 'react-toastify/dist/ReactToastify.css'
import Routes from './routes'
import { BrowserRouter } from 'react-router-dom'
import Header from './components/organisms/Header'

import { UserProvider } from './context/user/UserProvider'
import { SiteProvider } from './context/site/SiteProvider'
import { ProductProvider } from './context/products/ProductProvider'
import { PricingProvider } from './context/pricing/PricingProvider'
import { ShowHideProvider } from './context/showhide/ShowHideProvider'
import { WarehouseProvider } from './context/warehouse/WarehouseProvider'
import { ToastContainer } from 'react-toastify'
import { Flip } from 'react-toastify'
import ModalDeadFish from './components/organisms/Common/Modal/ModalDeadFish'
import { CookiesProvider } from 'react-cookie'
import SideNav from './components/organisms/Menu/SideNav'
import { Desktop } from './components/atoms/responsive'
import { StaffProvider } from './context/staff/StaffProvider'
import { DeliveryProvider } from './context/delivery/DeliveryProvider'
import Breadcrumbs from './components/molecules/Breadcrumbs'
import { SettingsProvider } from './context/settings/SettingsProvider'
import { CustomerProvider } from './context/customers/CustomerProvider'
import { AccountingProvider } from './context/accounting/AccountingProvider'
import { InventoryProvider } from './context/inventory/InventoryProvider'
import { IamProvider } from './context/iam/IamProvider'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const App = () => {
  return (
    <BrowserRouter>
      <CookiesProvider defaultSetOptions={{ path: '/' }}>
        <ShowHideProvider>
          <UserProvider>
            <SiteProvider>
              <SettingsProvider>
                <IamProvider>
                  <StaffProvider>
                    <CustomerProvider>
                      <AccountingProvider>
                        <InventoryProvider>
                          <DeliveryProvider>
                            <ProductProvider>
                              <PricingProvider>
                                <WarehouseProvider>
                                  <DndProvider backend={HTML5Backend}>
                                    <div className="page-container">
                                      <Header />
                                      <div className="d-flex">
                                        <Desktop>
                                          <div className="border-end border-bottom">
                                            <SideNav />
                                          </div>
                                        </Desktop>
                                        <div className="w-100">
                                          <Breadcrumbs />
                                          <Routes />
                                        </div>
                                      </div>
                                      <ModalDeadFish />
                                      <ToastContainer
                                        transition={Flip}
                                        theme="colored"
                                        autoClose={3000}
                                      />
                                    </div>
                                  </DndProvider>
                                </WarehouseProvider>
                              </PricingProvider>
                            </ProductProvider>
                          </DeliveryProvider>
                        </InventoryProvider>
                      </AccountingProvider>
                    </CustomerProvider>
                  </StaffProvider>
                </IamProvider>
              </SettingsProvider>
            </SiteProvider>
          </UserProvider>
        </ShowHideProvider>
      </CookiesProvider>
    </BrowserRouter>
  )
}

export default App
