import React from 'react'
import { Table } from 'react-bootstrap'
import { useUserContext } from '../../../context/user/UserProvider'

interface IRwTable {
  className?: string
  [x: string]: any
}
const RwTable: React.FC<IRwTable> = ({ children, className = '', ...rest }) => {
  const { isDarkMode } = useUserContext()

  return (
    <>
      <Table
        className={`border-top ${
          isDarkMode ? 'rw-table-dm' : 'rw-table'
        } ${className}`}
        {...rest}
      >
        {children}
      </Table>
    </>
  )
}

export default RwTable
