import React, { useEffect, useState } from 'react'
import { Button, Collapse, Stack } from 'react-bootstrap'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { useUserContext } from '../../../context/user/UserProvider'
import { BsFillMoonFill, BsSunFill } from 'react-icons/bs'
import { INavMenuItem } from '../../../context/site/types'
import { BsChevronDown, BsChevronUp, BsFillLockFill } from 'react-icons/bs'
import { Desktop } from '../../atoms/responsive'
import { useNavigate, useLocation } from 'react-router-dom'
import { isEmbedded, isHideNavPath } from '../../../helpers'
import { isAuthorized } from '../../../helpers/Authorization'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../../queries/user/keyFactory'
import { IUserData } from '../../../queries/user/types'

const SideNav: React.FC = () => {
  const {
    mainNavMenuItems,
    showSideNav,
    userShowSideNav,
    toggleMenuItemOpen,
    setMenuItemSelected,
  } = useSiteContext()

  const { isDarkMode, setDarkMode } = useUserContext()
  const queryClient = useQueryClient()
  const user = queryClient.getQueryData(userKeys.user) as IUserData
  const navigate = useNavigate()
  const location = useLocation()

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode)
  }

  const navClass = isDarkMode ? 'rw-side-nav-dm' : 'rw-side-nav'

  if (
    isHideNavPath(location.pathname) ||
    !showSideNav ||
    !userShowSideNav ||
    isEmbedded()
  ) {
    return <></>
  }

  return (
    <div className={`${navClass}`} style={{ minWidth: '200px' }}>
      {mainNavMenuItems &&
        (mainNavMenuItems as unknown as any[]).map(
          (item: INavMenuItem, index: number) => {
            return isAuthorized(user, item.permissions) ? (
              <div key={index}>
                <div
                  className={`rw-side-nav-item p-2 py-3 border-bottom ${
                    isDarkMode ? 'dm-card-bg' : 'bg-light'
                  }`}
                  onClick={() => {
                    toggleMenuItemOpen(item.id)
                  }}
                >
                  {item.title}
                  <span className="me-1 float-end" style={{ fontSize: '10px' }}>
                    {item.open ? <BsChevronDown /> : <BsChevronUp />}
                  </span>
                </div>
                <Collapse in={item.open}>
                  <Stack>
                    {item.children?.map((data: INavMenuItem, index: number) => {
                      const hasAccess = isAuthorized(user, data.permissions)
                      return (
                        <div
                          className={`d-flex border-bottom ${
                            hasAccess ? 'rw-side-nav-item' : ''
                          } ${data.selected ? 'selected' : ''} ${
                            isDarkMode
                              ? hasAccess
                                ? 'text-light'
                                : 'text-secondary'
                              : hasAccess
                              ? 'text-primary'
                              : 'text-secondary'
                          }`}
                          key={`${data.title}-${index}`}
                          id={data.id}
                          onClick={() => {
                            if (hasAccess) {
                              setMenuItemSelected(data.id)
                              navigate(data.link_to)
                            }
                          }}
                        >
                          <div
                            className={
                              data.selected ? 'ind-selected' : 'ind-unselected'
                            }
                          >
                            &nbsp;
                          </div>
                          <div className="p-3 ps-4 w-100">
                            <div className="d-flex">
                              <div>{data.title}</div>
                              {!hasAccess ? (
                                <div className="ms-auto">
                                  <BsFillLockFill />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </Stack>
                </Collapse>
              </div>
            ) : (
              <></>
            )
          },
        )}
      <Desktop>
        <Button
          variant={isDarkMode ? 'secondary' : 'primary'}
          onClick={toggleDarkMode}
          className="ms-3 mt-3 mb-3"
        >
          <span>{isDarkMode ? <BsSunFill /> : <BsFillMoonFill />}</span>
          <span className={'ms-2'}>
            {isDarkMode ? 'Disable' : 'Enable'} Dark Mode
          </span>
        </Button>
      </Desktop>
    </div>
  )
}

export default SideNav
