import React, { ReactNode } from 'react'
import RwTableCell, { IRwTableCell } from './RwTableCell'

interface IRwTableCellCenter extends IRwTableCell {}

const RwTableCellCenter: React.FC<IRwTableCellCenter> = ({
  children,
  ...rest
}) => {
  return (
    <RwTableCell align="center" {...rest}>
      {children}
    </RwTableCell>
  )
}

export default RwTableCellCenter
