import React, { useEffect, useState } from 'react'
import RwTable from '../../molecules/RwTable'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableHead from '../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import RwNav from '../../molecules/RwNav'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import { toast } from 'react-toastify'
import CustomSelect from '../../molecules/Select/CustomSelect'
import useDebounce from '../../../hooks/useDebounce'
import SearchInput from '../../molecules/SearchInput'
import { ISelectOption } from '../../../types/interfaces'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { Alert, Button, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap'
import { BsX } from 'react-icons/bs'
import RwTableCellCenter from '../../molecules/RwTable/RwTableCellCenter'
import { useInventoryTakerList } from '../../../queries/inventory'
import { IInventoryTakerFilter } from '../../../queries/inventory/types'
import { useInventoryContext } from '../../../context/inventory/InventoryProvider'
import {
  IWarehouseLocationStage,
  IWarehousePallet,
} from '../../../context/inventory/types'
import PalletCard from '../../organisms/Inventory/PalletCard'
import {
  BsCheckSquare,
  BsSquare,
  BsToggle2Off,
  BsToggle2On,
  BsExclamationTriangleFill,
} from 'react-icons/bs'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import SpinnerModal from '../../organisms/Common/Modal/SpinnerModal'
import StaffAvatarIcon from '../../organisms/Common/Icon/StaffAvatarIcon'
import ModalConfirmation from '../../organisms/Common/Modal/ModalConfirmation'
import ColorKeyModal from '../../organisms/Inventory/ColorKeyModal'
import { useLocation, useNavigate } from 'react-router-dom'
import SelectPhysicalInventoryModal from '../../organisms/Inventory/SelectPhysicalInventoryModal'
import RwButton from '../../molecules/RwButton/RwButton'
import {
  WMS_INVENTORY_TAKER_APPLY_UPDATES,
  WMS_INVENTORY_TAKER_UPDATE,
} from '../../../constants/iam'
import { useUserContext } from '../../../context/user/UserProvider'

export const PhysicalInventoryBreadcrumb: React.FC = () => {
  const { physicalInventory } = useInventoryContext()

  return <span>{physicalInventory?.name}</span>
}

interface IWmsInventoryTaker {
  hideNavs?: boolean
  hideCrumbs?: boolean
}

const WmsInventoryTaker: React.FC<IWmsInventoryTaker> = ({
  hideNavs = true,
  hideCrumbs = false,
}) => {
  const [showConfirmApplyUpdates, setShowConfirmApplyUpdates] =
    useState<boolean>(false)

  const [searchText, setSearchText] = useState<string>('')

  const debouncedSearchText = useDebounce(searchText, 500)

  const [showLoading, setShowLoading] = useState(false)
  const [showColorKey, setShowColorKey] = useState(false)
  const [showPhysicalInventoryModal, setShowPhysicalInventoryModal] =
    useState(false)
  const [showConfirmReset, setShowConfirmReset] = useState(false)
  const [resetId, setResetId] = useState<string>('')

  const navigate = useNavigate()
  const location = useLocation()

  const [tableData, setTableData] = useState<any>(null)
  const { isDarkMode } = useUserContext()

  const {
    inventoryTakerFilter,
    physicalInventory,
    physicalInventoryList,
    setInventoryTakerFilter,
    updateWarehouseLocation,
    pushStagingUpdates,
    getPhysicalInventoryList,
    getCurrentPhysicalInventory,
    physicalInventoryUpdateReport,
    resetLocation,
  } = useInventoryContext()

  const [filter, setFilter] =
    useState<IInventoryTakerFilter>(inventoryTakerFilter)

  const { setBackPath, setUserShowCrumbs, setShowNavs } = useSiteContext()

  const { data, refetch } = useInventoryTakerList(filter)

  useEffect(() => {
    setBackPath('/')
    setShowNavs(!hideNavs)
    setUserShowCrumbs(!hideCrumbs)
    getPhysicalInventoryList()
    getCurrentPhysicalInventory()

    if (inventoryTakerFilter?.search_text) {
      setSearchTextInput(
        inventoryTakerFilter.search_text
          ? inventoryTakerFilter.search_text
          : '',
      )
      setSearchText(inventoryTakerFilter.search_text)
    }
    loadData()
    return () => {
      setUserShowCrumbs(true)
      setShowNavs(true)
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    if (inventoryTakerFilter) {
      setFilter(inventoryTakerFilter)
    }
  }, [inventoryTakerFilter])

  useEffect(() => {
    setTableData(data)
  }, [data])

  let timeoutId: NodeJS.Timeout
  const loadData = () => {
    timeoutId = setTimeout(() => {
      refetch()
      loadData()
    }, 10000)
  }

  const handleRefresh = () => {
    refetch()
    toast.success('Data Reloaded')
  }

  const handleReset = () => {
    if (physicalInventory?.id) {
      resetLocation(resetId, physicalInventory?.id, () => {
        refetch()
      })
    }
  }

  const handleUpdateLocation = (
    warehouse_location_stage_id: string,
    is_verified: boolean,
  ) => {
    setShowLoading(true)
    updateWarehouseLocation(
      { warehouse_location_stage_id, is_verified },
      () => {
        refetch()
        setTimeout(() => {
          setShowLoading(false)
        }, 1000)
      },
    )
  }

  const handlePushStagingUpdates = () => {
    setShowLoading(true)
    pushStagingUpdates(() => {
      setShowLoading(false)
      getCurrentPhysicalInventory()
      refetch()
    })
  }

  const handleDownloadUpdateReport = () => {
    if (physicalInventory?.id) {
      physicalInventoryUpdateReport(physicalInventory.id)
    }
  }

  const handleStatusFilter = (scanned: string) => {
    setInventoryTakerFilter({ ...filter, scanned: scanned })
  }

  const handleEvenOddFilter = (even_odd: string) => {
    setInventoryTakerFilter({ ...filter, even_odd: even_odd })
  }

  const handleMismatchFilter = (mismatch: string) => {
    setInventoryTakerFilter({ ...filter, mismatch: mismatch })
  }

  const handleVerifiedFilter = (verified: string) => {
    setInventoryTakerFilter({ ...filter, verified: verified })
  }

  const handleUpdatePushedFilter = (is_update_pushed: string) => {
    setInventoryTakerFilter({ ...filter, is_update_pushed: is_update_pushed })
  }

  const handleSortBy = (sort_by: string) => {
    setInventoryTakerFilter({ ...filter, sort_by: sort_by })
  }

  const handleResetFilter = () => {
    setInventoryTakerFilter({
      search_text: '',
    })
    setSearchTextInput('')
  }

  const handleClearSearch = () => {
    setSearchText('')
    setSearchTextInput('')
  }

  const handlePhysicalInventorySelect = (id: string | undefined) => {
    // console.log('handlePhysicalInventorySelect', id)
  }

  const setSearchTextInput = (searchText: string) => {
    const elems = document.getElementsByName(
      'searchText',
    ) as NodeListOf<HTMLInputElement>

    if (elems && elems.length > 0) {
      elems.forEach((elem) => {
        elem.value = searchText
      })
    }
  }

  useEffect(() => {
    if (debouncedSearchText.length > 0 || debouncedSearchText.length === 0) {
      setInventoryTakerFilter({
        ...inventoryTakerFilter,
        search_text: debouncedSearchText,
      })
    }
  }, [debouncedSearchText])

  const sortByOptions: Array<ISelectOption> = [
    {
      value: 'location',
      label: 'Location',
      id: 'location',
    },
    {
      value: 'location-type',
      label: 'Location Type',
      id: 'location-type',
    },
  ]

  const yesNoOptions: Array<ISelectOption> = [
    { value: 'Yes', label: 'Yes', id: 'Yes' },
    { value: 'No', label: 'No', id: 'No' },
  ]

  const evenOddOptions: Array<ISelectOption> = [
    { value: 'Even', label: 'Even', id: 'Even' },
    { value: 'Odd', label: 'Odd', id: 'Odd' },
  ]

  const clearSearchButton = (
    <Button variant="outline-secondary" onClick={handleClearSearch}>
      <OverlayTrigger placement={'right'} overlay={<Tooltip>Clear</Tooltip>}>
        <div>
          <BsX />
        </div>
      </OverlayTrigger>
    </Button>
  )

  return (
    <div>
      <RwNavBar>
        <RwNavItem variant="refresh" onClick={handleRefresh} />
        <RwNavItem
          className="ms-5"
          variant="save"
          title="Apply Updates"
          onClick={() => {
            setShowConfirmApplyUpdates(true)
          }}
          disabled={physicalInventory?.is_update_ran}
          permission={WMS_INVENTORY_TAKER_APPLY_UPDATES}
        />
        <RwNavItem
          className=""
          variant="audit"
          onClick={() => {
            navigate(`${location.pathname}/${physicalInventory?.id}`)
          }}
          disabled={!physicalInventory?.is_update_ran}
        />
        <RwNavItem
          className=""
          title="Audit Report"
          variant="excel"
          onClick={handleDownloadUpdateReport}
          disabled={!physicalInventory?.is_update_ran}
        />
        {/* <RwNavItem
          className=""
          variant="calendar-day"
          title="Select Inventory"
          onClick={() => {
            setShowPhysicalInventoryModal(true)
          }}
        /> */}

        <RwNavItem
          className="ms-auto me-3"
          variant="help"
          title="Color Key"
          onClick={() => {
            setShowColorKey(true)
          }}
        />
        <RwNav>
          <CustomSelect
            initialValue={inventoryTakerFilter.sort_by}
            title="Sort By"
            width="140px"
            handleSelect={handleSortBy}
            options={sortByOptions}
            placement="left-start"
          />
        </RwNav>
      </RwNavBar>
      <RwNavBar expand="lg">
        <RwNav>
          <SearchInput
            width="125px"
            name="searchText"
            suffix={clearSearchButton}
            minLength={1}
            flush
            handleChange={(text) => {
              setSearchText(text)
            }}
          />
        </RwNav>
        <RwNav>
          <CustomSelect
            initialValue={inventoryTakerFilter.even_odd}
            title="Bay"
            width="80px"
            handleSelect={handleEvenOddFilter}
            options={evenOddOptions}
          />
        </RwNav>
        <RwNav>
          <CustomSelect
            initialValue={inventoryTakerFilter.scanned}
            title="Scanned"
            width="110px"
            handleSelect={handleStatusFilter}
            options={yesNoOptions}
          />
        </RwNav>
        <RwNav>
          <CustomSelect
            initialValue={inventoryTakerFilter.mismatch}
            title="Mismatch"
            width="110px"
            handleSelect={handleMismatchFilter}
            options={yesNoOptions}
          />
        </RwNav>
        <RwNav>
          <CustomSelect
            initialValue={inventoryTakerFilter.verified}
            title="Verified"
            width="100px"
            handleSelect={handleVerifiedFilter}
            options={yesNoOptions}
          />
        </RwNav>
        <RwNav>
          <CustomSelect
            initialValue={inventoryTakerFilter.is_update_pushed}
            title="Updated"
            width="110px"
            handleSelect={handleUpdatePushedFilter}
            options={yesNoOptions}
          />
        </RwNav>
        <RwNavItem
          variant="reset-filters"
          onClick={handleResetFilter}
          className="ms-auto"
        />
      </RwNavBar>
      <div>
        {physicalInventory?.is_update_ran ? (
          <div className="border-bottom py-2 px-3 text-center bg-info text-light fs-3">
            STAGE INVENTORY APPLIED {physicalInventory.end_date?.toString()}
          </div>
        ) : (
          <></>
        )}
        <div className="p-3">
          {tableData?.length >= 500 ? (
            'Max results of 500 reached'
          ) : (
            <div className="mb-1">{tableData?.length} results</div>
          )}
          <RwTable hover data={tableData}>
            <RwTableHead className={`sticky-top`}>
              <RwTableRow>
                <RwTableCell className="border-bottom-0" colSpan={6} as="th">
                  &nbsp;
                </RwTableCell>
                <RwTableCellCenter
                  className="border-bottom-0 border-start"
                  colSpan="3"
                  as="th"
                >
                  STAGE
                </RwTableCellCenter>
                <RwTableCellCenter
                  className="border-bottom-0 border-start"
                  colSpan="3"
                  as="th"
                >
                  CURRENT
                </RwTableCellCenter>
                <RwTableCellCenter
                  className="border-bottom-0 border-start mx-0 px-0"
                  as="th"
                >
                  MISMATCH
                </RwTableCellCenter>
              </RwTableRow>
              <RwTableRow>
                <RwTableCellCenter as="th">&nbsp;</RwTableCellCenter>
                <RwTableCellCenter as="th">User</RwTableCellCenter>
                <RwTableCellCenter as="th">Scanned</RwTableCellCenter>
                <RwTableCellCenter as="th">Updated</RwTableCellCenter>
                <RwTableCell as="th">Location</RwTableCell>
                <RwTableCell as="th">Type</RwTableCell>
                <RwTableCell className="border-start" as="th">
                  Product
                </RwTableCell>
                <RwTableCell as="th">Count</RwTableCell>
                <RwTableCell as="th">Pallets</RwTableCell>
                <RwTableCell className="border-start" as="th">
                  Product
                </RwTableCell>
                <RwTableCell as="th">Count</RwTableCell>
                <RwTableCell as="th">Pallets</RwTableCell>
                <RwTableCellCenter className="border-start" as="th">
                  Verified
                </RwTableCellCenter>
              </RwTableRow>
            </RwTableHead>
            <RwTableBody style={{ position: 'sticky' }}>
              {tableData?.map((item: IWarehouseLocationStage) => {
                let rowClass = ''
                if (item.is_complete) {
                  rowClass = item.is_update_pushed
                    ? 'rw-inventory-taker-update-pushed'
                    : item.is_mismatch
                    ? item.is_verified
                      ? 'rw-inventory-taker-mismatch-verified'
                      : 'rw-inventory-taker-mismatch-scanned'
                    : 'rw-inventory-taker-success-scanned'
                } else {
                  rowClass = item.is_mismatch
                    ? 'rw-inventory-taker-mismatch'
                    : ''
                }
                return (
                  <RwTableRow key={item.id} className={rowClass}>
                    <RwTableCellCenter>
                      {item.is_complete && !item.is_update_pushed ? (
                        <RwButton
                          onClick={() => {
                            setResetId(
                              item?.warehouse_location_stage_id
                                ? item.warehouse_location_stage_id
                                : '',
                            )
                            setShowConfirmReset(true)
                          }}
                          permission={WMS_INVENTORY_TAKER_UPDATE}
                        >
                          <FaArrowRotateLeft />
                        </RwButton>
                      ) : (
                        <></>
                      )}
                    </RwTableCellCenter>
                    <RwTableCellCenter>
                      <StaffAvatarIcon
                        photo_url={
                          item.updated_by_photo_url
                            ? item.updated_by_photo_url
                            : item.created_by_photo_url
                        }
                        staff_name={
                          item.updated_by_name
                            ? item.updated_by_name
                            : item.created_by_name
                        }
                      />
                    </RwTableCellCenter>
                    <RwTableCellCenter>
                      <div className="text-dark fs-5 my-2">
                        {item.is_complete ? <BsCheckSquare /> : <BsSquare />}
                      </div>
                    </RwTableCellCenter>
                    <RwTableCellCenter>
                      <div className="text-dark fs-5 my-2">
                        {item.is_update_pushed ? (
                          <BsCheckSquare />
                        ) : (
                          <BsSquare />
                        )}
                      </div>
                    </RwTableCellCenter>
                    <RwTableCell>{item.aisle_bay_level}</RwTableCell>
                    <RwTableCell>{item.location_type_name}</RwTableCell>
                    <RwTableCell className="border-start">
                      {item.warehouse_case_stage?.products_model}
                    </RwTableCell>
                    <RwTableCell>
                      {item.warehouse_case_stage?.location_case_qty}
                    </RwTableCell>
                    <RwTableCell>
                      <Stack gap={2} direction="horizontal">
                        {item.pallets_stage?.map((pallet: IWarehousePallet) => (
                          <PalletCard key={pallet.pallet_upc} pallet={pallet} />
                        ))}
                      </Stack>
                    </RwTableCell>
                    <RwTableCell className="border-start">
                      {item.warehouse_case_physical?.products_model}
                    </RwTableCell>
                    <RwTableCell>
                      {item.warehouse_case_physical?.location_case_qty}
                    </RwTableCell>
                    <RwTableCell>
                      <Stack gap={2} direction="horizontal">
                        {item.pallets_physical?.map(
                          (pallet: IWarehousePallet) => (
                            <PalletCard
                              key={pallet.pallet_upc}
                              pallet={pallet}
                            />
                          ),
                        )}
                      </Stack>
                    </RwTableCell>
                    <RwTableCellCenter className="border-start">
                      {item.is_mismatch && item.is_complete ? (
                        !item.is_verified ? (
                          <RwButton
                            className="fs-2"
                            onClick={() => {
                              handleUpdateLocation(
                                item.warehouse_location_stage_id,
                                true,
                              )
                            }}
                            permission={WMS_INVENTORY_TAKER_UPDATE}
                          >
                            <BsToggle2Off />
                          </RwButton>
                        ) : (
                          <RwButton
                            className="fs-2 text-success"
                            onClick={() => {
                              handleUpdateLocation(
                                item.warehouse_location_stage_id,
                                false,
                              )
                            }}
                            permission={WMS_INVENTORY_TAKER_UPDATE}
                          >
                            <BsToggle2On />
                          </RwButton>
                        )
                      ) : (
                        <></>
                      )}
                    </RwTableCellCenter>
                  </RwTableRow>
                )
              })}
            </RwTableBody>
          </RwTable>
        </div>
      </div>
      <SpinnerModal
        showModal={showLoading}
        setShowModal={setShowLoading}
        title="Updating..."
      />
      <ColorKeyModal showModal={showColorKey} setShowModal={setShowColorKey} />
      <SelectPhysicalInventoryModal
        showModal={showPhysicalInventoryModal}
        setShowModal={setShowPhysicalInventoryModal}
        items={physicalInventoryList}
        onSelect={handlePhysicalInventorySelect}
      />
      <ModalConfirmation
        showModal={showConfirmApplyUpdates}
        setShowModal={setShowConfirmApplyUpdates}
        handleConfirm={handlePushStagingUpdates}
        title="Apply Staging Updates"
        confirmButtonText="Apply Updates"
        body={
          <div>
            <div className="fs-5 mb-4">
              Are you sure you want to apply the staging updates?
            </div>
            <Alert variant="warning">
              <div className="fs-4 fw-bold">
                <BsExclamationTriangleFill className="me-2" />
                IMPORTANT!
              </div>
              <div>THIS WILL UPDATE ACTUAL WAREHOUSE INVENTORY</div>
            </Alert>
            <div className="fs-5">
              Only mismatched locations that have been verified will be updated.
            </div>
          </div>
        }
      />
      <ModalConfirmation
        showModal={showConfirmReset}
        setShowModal={setShowConfirmReset}
        handleConfirm={handleReset}
        title="Reset Location"
        confirmButtonText="Reset"
        body={
          <div className="fs-5 mb-4">
            Are you sure you want to reset this location?
          </div>
        }
      />
    </div>
  )
}

export default WmsInventoryTaker
