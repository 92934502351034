import React, { useEffect, useState } from 'react'
import RwTable from '../../molecules/RwTable'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableHead from '../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import RwNav from '../../molecules/RwNav'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import { toast } from 'react-toastify'
import CustomSelect from '../../molecules/Select/CustomSelect'
import useDebounce from '../../../hooks/useDebounce'
import SearchInput from '../../molecules/SearchInput'
import { ISelectOption } from '../../../types/interfaces'
import { useSiteContext } from '../../../context/site/SiteProvider'
import { Alert, Button, OverlayTrigger, Stack, Tooltip } from 'react-bootstrap'
import { BsX } from 'react-icons/bs'
import RwTableCellCenter from '../../molecules/RwTable/RwTableCellCenter'
import {
  useInventoryTakerList,
  useInventoryTakerLogList,
} from '../../../queries/inventory'
import {
  IInventoryTakerFilter,
  IInventoryTakerLogFilter,
} from '../../../queries/inventory/types'
import { useInventoryContext } from '../../../context/inventory/InventoryProvider'
import {
  IWarehouseLocationStage,
  IWarehousePallet,
} from '../../../context/inventory/types'
import PalletCard from '../../organisms/Inventory/PalletCard'
import {
  BsCheckSquare,
  BsSquare,
  BsToggle2Off,
  BsToggle2On,
  BsExclamationTriangleFill,
} from 'react-icons/bs'
import SpinnerModal from '../../organisms/Common/Modal/SpinnerModal'
import StaffAvatarIcon from '../../organisms/Common/Icon/StaffAvatarIcon'
import ModalConfirmation from '../../organisms/Common/Modal/ModalConfirmation'
import ColorKeyModal from '../../organisms/Inventory/ColorKeyModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RwTableCellEnd from '../../molecules/RwTable/RwTableCellEnd'

export const PhysicalInventoryLogsBreadcrumb: React.FC = () => {
  const { physicalInventory } = useInventoryContext()

  return <span>Audit</span>
}

interface IWmsInventoryTakerLogs {
  hideNavs?: boolean
  hideCrumbs?: boolean
}

const WmsInventoryTakerLogs: React.FC<IWmsInventoryTakerLogs> = ({
  hideNavs = true,
  hideCrumbs = false,
}) => {
  const { physical_inventory_id } = useParams()

  const [showConfirmApplyUpdates, setShowConfirmApplyUpdates] =
    useState<boolean>(false)

  const [searchText, setSearchText] = useState<string>('')

  const debouncedSearchText = useDebounce(searchText, 500)

  const [showLoading, setShowLoading] = useState(false)
  const [showColorKey, setShowColorKey] = useState(false)

  const [tableData, setTableData] = useState<any>(null)

  const navigate = useNavigate()
  const location = useLocation()

  const {
    inventoryTakerLogFilter,
    setInventoryTakerLogFilter,
    updateWarehouseLocation,
    pushStagingUpdates,
  } = useInventoryContext()

  const [filter, setFilter] = useState<IInventoryTakerLogFilter>(
    inventoryTakerLogFilter,
  )

  const { backPath, setBackPath, setUserShowCrumbs, setShowNavs } =
    useSiteContext()

  const { data, refetch } = useInventoryTakerLogList(filter)

  useEffect(() => {
    const newBackPath = location.pathname.replace(
      `/${physical_inventory_id}`,
      '',
    )
    setBackPath(newBackPath)
    setShowNavs(!hideNavs)
    setUserShowCrumbs(!hideCrumbs)
    setInventoryTakerLogFilter({
      ...filter,
      physical_inventory_id: physical_inventory_id,
    })

    if (inventoryTakerLogFilter?.search_text) {
      setSearchTextInput(
        inventoryTakerLogFilter.search_text
          ? inventoryTakerLogFilter.search_text
          : '',
      )
      setSearchText(inventoryTakerLogFilter.search_text)
    }
    loadData()
    return () => {
      setUserShowCrumbs(true)
      setShowNavs(true)
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    if (inventoryTakerLogFilter) {
      setFilter(inventoryTakerLogFilter)
    }
  }, [inventoryTakerLogFilter])

  useEffect(() => {
    setTableData(data)
  }, [data])

  let timeoutId: NodeJS.Timeout
  const loadData = () => {
    timeoutId = setTimeout(() => {
      refetch()
      loadData()
    }, 10000)
  }

  const handleRefresh = () => {
    refetch()
    toast.success('Data Reloaded')
  }

  const handleUpdateLocation = (
    warehouse_location_stage_id: string,
    is_verified: boolean,
  ) => {
    setShowLoading(true)
    updateWarehouseLocation(
      { warehouse_location_stage_id, is_verified },
      () => {
        refetch()
        setTimeout(() => {
          setShowLoading(false)
        }, 1000)
      },
    )
  }

  const handlePushStagingUpdates = () => {
    setShowLoading(true)
    pushStagingUpdates(() => {
      setShowLoading(false)
      refetch()
    })
  }

  const handleEvenOddFilter = (even_odd: string) => {
    setInventoryTakerLogFilter({ ...filter, even_odd: even_odd })
  }

  const handleSortBy = (sort_by: string) => {
    setInventoryTakerLogFilter({ ...filter, sort_by: sort_by })
  }

  const handleResetFilter = () => {
    setInventoryTakerLogFilter({
      physical_inventory_id: physical_inventory_id,
      search_text: '',
    })
    setSearchTextInput('')
  }

  const handleClearSearch = () => {
    setSearchText('')
    setSearchTextInput('')
  }

  const handleClose = () => {
    navigate(backPath)
  }

  const setSearchTextInput = (searchText: string) => {
    const elems = document.getElementsByName(
      'searchText',
    ) as NodeListOf<HTMLInputElement>

    if (elems && elems.length > 0) {
      elems.forEach((elem) => {
        elem.value = searchText
      })
    }
  }

  useEffect(() => {
    if (debouncedSearchText.length > 0 || debouncedSearchText.length === 0) {
      setInventoryTakerLogFilter({
        ...inventoryTakerLogFilter,
        physical_inventory_id: physical_inventory_id,
        search_text: debouncedSearchText,
      })
    }
  }, [debouncedSearchText])

  const sortByOptions: Array<ISelectOption> = [
    {
      value: 'location',
      label: 'Location',
      id: 'location',
    },
    {
      value: 'location-type',
      label: 'Location Type',
      id: 'location-type',
    },
  ]

  const evenOddOptions: Array<ISelectOption> = [
    { value: 'Even', label: 'Even', id: 'Even' },
    { value: 'Odd', label: 'Odd', id: 'Odd' },
  ]

  const clearSearchButton = (
    <Button variant="outline-secondary" onClick={handleClearSearch}>
      <OverlayTrigger placement={'right'} overlay={<Tooltip>Clear</Tooltip>}>
        <div>
          <BsX />
        </div>
      </OverlayTrigger>
    </Button>
  )

  return (
    <div>
      <RwNavBar>
        <RwNavItem variant="close" onClick={handleClose} />
        <RwNavItem variant="refresh" onClick={handleRefresh} />
        <RwNav className="ms-auto">
          <CustomSelect
            initialValue={inventoryTakerLogFilter.sort_by}
            title="Sort By"
            width="140px"
            handleSelect={handleSortBy}
            options={sortByOptions}
            placement="left-start"
          />
        </RwNav>
      </RwNavBar>

      <RwNavBar expand="lg">
        <RwNav>
          <SearchInput
            width="250px"
            name="searchText"
            suffix={clearSearchButton}
            minLength={1}
            flush
            handleChange={(text) => {
              setSearchText(text)
            }}
          />
        </RwNav>
        <RwNav>
          <CustomSelect
            initialValue={inventoryTakerLogFilter.even_odd}
            title="Bay"
            width="120px"
            handleSelect={handleEvenOddFilter}
            options={evenOddOptions}
          />
        </RwNav>
        <RwNavItem
          variant="reset-filters"
          onClick={handleResetFilter}
          className="ms-auto"
        />
      </RwNavBar>
      <div className="p-3">
        <div className="mb-1">{tableData?.length} results</div>
        <RwTable hover data={tableData}>
          <RwTableHead>
            <RwTableRow>
              <RwTableCell as="th">Location</RwTableCell>
              <RwTableCell as="th">Type</RwTableCell>
              <RwTableCell className="border-start" as="th">
                Product
              </RwTableCell>
              <RwTableCell width="50%" as="th">
                Reason
              </RwTableCell>
              <RwTableCellEnd as="th">Qty</RwTableCellEnd>
              <RwTableCellEnd as="th">Balance</RwTableCellEnd>
            </RwTableRow>
          </RwTableHead>
          <RwTableBody>
            {tableData?.map((item: IWarehouseLocationStage) => {
              return (
                <RwTableRow key={item.id}>
                  <RwTableCell>{item.aisle_bay_level}</RwTableCell>
                  <RwTableCell>{item.location_type_name}</RwTableCell>
                  <RwTableCell className="border-start">
                    {item.products_model}
                  </RwTableCell>
                  <RwTableCell>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.reason_text || '',
                      }}
                    ></span>
                  </RwTableCell>
                  <RwTableCellEnd>{item.qty}</RwTableCellEnd>
                  <RwTableCellEnd>{item.balance}</RwTableCellEnd>
                </RwTableRow>
              )
            })}
          </RwTableBody>
        </RwTable>
      </div>
      <SpinnerModal
        showModal={showLoading}
        setShowModal={setShowLoading}
        title="Updating..."
      />
      <ColorKeyModal showModal={showColorKey} setShowModal={setShowColorKey} />
      <ModalConfirmation
        showModal={showConfirmApplyUpdates}
        setShowModal={setShowConfirmApplyUpdates}
        handleConfirm={handlePushStagingUpdates}
        title="Apply Staging Updates"
        confirmButtonText="Apply Updates"
        body={
          <div>
            <div className="fs-5 mb-4">
              Are you sure you want to apply the staging updates?
            </div>
            <Alert variant="warning">
              <div className="fs-4 fw-bold">
                <BsExclamationTriangleFill className="me-2" />
                IMPORTANT!
              </div>
              <div>THIS WILL UPDATE ACTUAL WAREHOUSE INVENTORY</div>
            </Alert>
            <div className="fs-5">
              Only mismatched locations that have been verified will be updated.
            </div>
          </div>
        }
      />
    </div>
  )
}

export default WmsInventoryTakerLogs
