import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSiteContext } from '../../../context/site/SiteProvider'
import RwNavItem from '../../molecules/RwNav/RwNavItem'
import RwNavBar from '../../molecules/RwNav/RwNavBar'
import RwTable from '../../molecules/RwTable'
import RwTableCell from '../../molecules/RwTable/RwTableCell'
import RwTableHead from '../../molecules/RwTable/RwTableHead'
import RwTableBody from '../../molecules/RwTable/RwTableBody'
import DisplayCheck from '../../atoms/DisplayCheck'
import RwTableCellCenter from '../../molecules/RwTable/RwTableCellCenter'
import RwTableRow from '../../molecules/RwTable/RwTableRow'
import { useProductsDeals } from '../../../queries/products'
import { useQueryClient } from '@tanstack/react-query'
import { IProductsDeal } from '../../../context/products/types'

export const ProductsDealsBreadcrumb = () => {
  return <span>Products Deals</span>
}

const ProductsDeals: React.FC = () => {
  const [tableSort, setTableSort] = useState<any>(null)
  const [tableData, setTableData] = useState<IProductsDeal[]>([])
  const navigate = useNavigate()
  const { setBackPath } = useSiteContext()
  const queryClient = useQueryClient()
  const { data, refetch } = useProductsDeals()

  useEffect(() => {
    setBackPath('/')

    return () => {
      queryClient.removeQueries(['productsDeals'])
    }
  }, [])

  useEffect(() => {
    if (data) {
      setTableData(data)
    }
  }, [data])

  const handleCreateButtonClick = () => {
    navigate('/accounting/products/deal')
  }

  const handleRefresh = () => {
    refetch()
  }

  const handleRowDoubleClick = (deal_id: string) => {
    navigate(`/accounting/products/deal/${deal_id}`)
  }

  const handleChangeSort = (tableSort: any) => {
    setTableSort(tableSort)
  }

  return (
    <div>
      <RwNavBar title="Product Deals">
        <RwNavItem variant="add" onClick={handleCreateButtonClick} />
        <RwNavItem variant="refresh" onClick={handleRefresh} />
      </RwNavBar>

      <div className={'p-4'}>
        <RwTable
          hover
          data={tableData}
          sort={tableSort}
          handleChangeSort={handleChangeSort}
        >
          <RwTableHead>
            <RwTableRow>
              <RwTableCell sortProp="deal_name" as="th">
                Deal Name
              </RwTableCell>
              <RwTableCell sortProp="deal_fraction" as="th">
                Discount
              </RwTableCell>
              <RwTableCell sortProp="start_date" as="th">
                Start Date
              </RwTableCell>
              <RwTableCell sortProp="end_date" as="th">
                End Date
              </RwTableCell>
              <RwTableCellCenter sortProp="active" as="th">
                Active
              </RwTableCellCenter>
            </RwTableRow>
          </RwTableHead>
          <RwTableBody>
            {tableData?.map((deal: IProductsDeal, index: number) => {
              return (
                <RwTableRow
                  onDoubleClick={() => handleRowDoubleClick(deal.deal_id)}
                  key={deal.deal_id}
                >
                  <RwTableCell>{deal.deal_name}</RwTableCell>
                  <RwTableCell>{deal.deal_fraction + '%'}</RwTableCell>
                  <RwTableCell>{deal.start_date}</RwTableCell>
                  <RwTableCell>{deal.end_date}</RwTableCell>
                  <RwTableCellCenter>
                    <DisplayCheck value={deal.is_active || false} />
                  </RwTableCellCenter>
                </RwTableRow>
              )
            })}
          </RwTableBody>
        </RwTable>
      </div>
    </div>
  )
}

export default ProductsDeals
