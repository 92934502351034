import React, {
  JSXElementConstructor,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { Button, OverlayTrigger, Table, Tooltip } from 'react-bootstrap'
import { BsArrowDown, BsArrowUp, BsArrowRight } from 'react-icons/bs'
import { ISortColumn } from '.'
import { useUserContext } from '../../../context/user/UserProvider'

export interface IRwTableCell {
  children?:
    | Array<ReactElement<any, string | JSXElementConstructor<any>>>
    | ReactElement<any, string | JSXElementConstructor<any>>
    | string
    | number
    | any
  as?: 'td' | 'th'
  expand?: boolean | string | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  align?: 'start' | 'center' | 'end'
  className?: string
  sortable?: boolean
  sortProp?: string
  sort?: ISortColumn
  handleSortClick?: (sortProp: string) => {}
  [x: string]: any
}

const RwTableCell: React.FC<IRwTableCell> = ({
  children,
  as = 'td',
  expand = false,
  align = 'start',
  className = '',
  sortable = true,
  sortProp = null,
  sort = null,
  handleSortClick,
  ...rest
}) => {
  const [displaySort, setDisplaySort] = useState(false)
  const { isDarkMode } = useUserContext()
  const displayClass = expand ? `d-none d-${expand}-table-cell` : ''
  const alignClass = align ? `text-${align}` : ''
  const cursorClass = sortable && sortProp ? 'cursor-pointer' : ''
  const idProp = sortProp ? { id: sortProp } : null
  const sortedArrow =
    sort?.dir === 'asc' ? (
      <BsArrowUp className="ms-1" />
    ) : (
      <BsArrowDown className="ms-1" />
    )
  const onClickProp =
    sortable && sortProp
      ? {
          onClick: () => {
            handleSortClick && handleSortClick(sortProp)
          },
        }
      : {}

  const isNegative = (value: any) => typeof value === 'number' && value < 0

  const cellStyle = isNegative(children) ? { color: 'red', fontWeight: 'bold' } : {}

  const tooltipSortText = sort?.name === sortProp ? 'Sorted ' : 'Sort '
  const dirText =
    sort?.name !== sortProp || sort?.dir === 'asc' ? (
      <span>
        A<BsArrowRight className="mx-2" />Z
      </span>
    ) : (
      <span>
        Z<BsArrowRight className="mx-2" />A
      </span>
    )
  const tooltipText = (
    <span>
      {tooltipSortText} {dirText}
    </span>
  )
  const tooltipPlacement =
    align === 'start'
      ? 'bottom-start'
      : align === 'center'
      ? 'bottom'
      : 'bottom-end'

  let childrenDisplay
  if (sortable && sortProp) {
    let leftPad = <></>
    let rightPad = <></>
    switch (align) {
      case 'center':
        leftPad = <BsArrowUp className="me-1 invisible" />
        rightPad = <BsArrowUp className="ms-1 invisible" />
        break
      case 'end':
        leftPad = <BsArrowUp className="me-1 invisible" />
        break
      default:
        rightPad = <BsArrowUp className="ms-1 invisible" />
    }

    childrenDisplay =
      displaySort && sortable && sortProp ? (
        <span>
          {leftPad}
          <span>{children}</span>
          {sortedArrow}
        </span>
      ) : !displaySort && sortable && sort?.name === sortProp ? (
        <span>
          {leftPad}
          <span>{children}</span>
          {sortedArrow}
        </span>
      ) : (
        <span>
          {leftPad}
          <span>{children}</span>
          {rightPad}
        </span>
      )
  } else {
    childrenDisplay = children
  }

  return (
    <>
      {as === 'td' ? (
        <td
          className={`${className} ${displayClass} ${alignClass}`}
          style={cellStyle}
          {...idProp}
          {...rest}
        >
          {children}
        </td>
      ) : (
        <th
          className={`${className} ${displayClass} ${alignClass} ${cursorClass}`}
          style={cellStyle}
          {...idProp}
          {...rest}
          {...onClickProp}
          onMouseEnter={() => {
            setDisplaySort(true)
          }}
          onMouseLeave={() => {
            setDisplaySort(false)
          }}
        >
          {sortable && sortProp ? (
            <OverlayTrigger
              placement={tooltipPlacement}
              overlay={
                <Tooltip
                  className={`rw-tooltip-no-arrow p-2 rounded `}
                  id={`tooltip`}
                >
                  {tooltipText}
                </Tooltip>
              }
            >
              <div>{childrenDisplay}</div>
            </OverlayTrigger>
          ) : (
            <div>{childrenDisplay}</div>
          )}
        </th>
      )}
    </>
  )
}

export default RwTableCell
