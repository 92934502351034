import React from 'react'
import { HEADER_HEIGHT_DESKTOP } from '../../../constants/constants'

const HeaderSpacer: React.FC = () => {
  return (
    <div
      className={`spacer-under-header-nav-bar`}
      style={{ height: `${HEADER_HEIGHT_DESKTOP}px` }}
    />
  )
}

export default HeaderSpacer
