import React from 'react'
import Modal from 'react-bootstrap/Modal'
import RwTable from '../../molecules/RwTable2'
import RwTableBody from '../../molecules/RwTable2/RwTableBody'
import RwTableRow from '../../molecules/RwTable2/RwTableRow'
import RwTableCell from '../../molecules/RwTable2/RwTableCell'
import { Button } from 'react-bootstrap'

interface IColorKeyModal {
  showModal: boolean
  setShowModal: (newShowValue: boolean) => void
  title?: string
  className?: string
}

const ColorKeyModal: React.FC<IColorKeyModal> = ({
  showModal,
  setShowModal,
  title = 'Color Key',
  className = '',
}) => {
  return (
    <Modal
      show={showModal}
      animation={false}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header className={className} closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body className={className}>
        <div className="fs-4">
          <RwTable>
            <RwTableBody>
              <RwTableRow className="">
                <RwTableCell>Match Not Scanned</RwTableCell>
              </RwTableRow>
              <RwTableRow className="rw-inventory-taker-mismatch">
                <RwTableCell>Mismatch Not Scanned</RwTableCell>
              </RwTableRow>
              <RwTableRow className="rw-inventory-taker-success-scanned">
                <RwTableCell>Match Scanned</RwTableCell>
              </RwTableRow>
              <RwTableRow className="rw-inventory-taker-mismatch-scanned">
                <RwTableCell>Mismatch Scanned</RwTableCell>
              </RwTableRow>
              <RwTableRow className="rw-inventory-taker-mismatch-verified">
                <RwTableCell>Mismatch Verified</RwTableCell>
              </RwTableRow>
              <RwTableRow className="rw-inventory-taker-update-pushed">
                <RwTableCell>Update Applied</RwTableCell>
              </RwTableRow>
            </RwTableBody>
          </RwTable>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ColorKeyModal
