import React, {
  JSXElementConstructor,
  MouseEventHandler,
  ReactElement,
  ReactNode,
} from 'react'
import { ISortColumn } from '.'

export interface IRwTableRow {
  children:
    | Array<ReactElement<any, string | JSXElementConstructor<any>>>
    | ReactElement<any, string | JSXElementConstructor<any>>
  className?: string
  highlightedClassName?: string
  isHighlighted?: boolean
  align?: 'top' | 'middle' | 'bottom'
  sortable?: boolean
  handleSortClick?: (sortProp: string) => {}
  sort?: ISortColumn
  [x: string]: any
}

const RwTableRow: React.FC<IRwTableRow> = ({
  children,
  className = '',
  highlightedClassName = '',
  isHighlighted = false,
  align = 'middle',
  sortable = true,
  handleSortClick,
  sort,
  ...rest
}) => {
  const alignClass = `align-${align}`

  return (
    <tr
      className={`${
        isHighlighted === false ? className : highlightedClassName
      } ${alignClass}`}
      {...rest}
    >
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          handleSortClick: handleSortClick,
          sort: sort,
          sortable: sortable,
        })
      })}
    </tr>
  )
}

export default RwTableRow
